import React from "react";

export const PrivacyPolicyContent = () => (
  <>
    <h2>Privacy Policy</h2>
    <h3>プライバシーポリシー</h3>
    <p>
      Suprieve
      Holdings株式会社（以下「当社」といいます。）は、一般労働派遣業、メディア事業、広告事業、システム事業、教育事業などすべての事業を実施する上で、お客様の個人情報がプライバシーを構成する重要な情報であることを深く認識し、業務において個人情報を取扱う場合には、個人情報に関する法令及び個人情報のために定めた社内規定を定め、また、組織体制を整備し、個人情報の適切な保護に努めることにより、お客様を尊重し、当社に対する期待と信頼に応えていきます。
      <br />
      <br />
      法令規範の遵守
      <br />
      <br />
      当社は、個人情報に関する法令、国が定める指針、その他の規範及び社会秩序を遵守し、個人情報の適切な保護に努めます。
      <br />
      <br />
      個人情報の取得、利用、提供
      <br />
      <br />
      当社は、事業活動の範囲内で個人情報の利用目的を特定し、その目的達成のために限度で公正かつ適正に個人情報の取得、利用及び提供を行います。また、取得した個人情報の目的外利用をしないよう処置を講じます。
      <br />
      <br />
      個人情報の適切な管理
      <br />
      <br />
      当社は、当社が取り扱う個人情報について、不正アクセス、紛失、破壊、改ざん、漏洩などの危険を十分に認識し、合理的な安全対策を実施するともに、問題が発生した場合は適切な是正措置を講じます。
      <br />
      <br />
      継続的改善
      <br />
      <br />
      当社は、個人情報保護に関する管理規程及び管理体制を整備し、全社員で徹底して運用するとともに定期的な見直しを行い、継続的な改善に努めます。
      <br />
      <br />
      お問い合わせへの対応
      <br />
      <br />
      当社が取り扱う個人情報について、本人から開示、訂正、利用停止及び苦情相談等のお問合せがあった場合は適正に対応します。
      <br />
      <br />
      <br />
      <br />
      個人情報の取扱いについて
      <br />
      <br />
      個人情報の定義
      <br />
      <br />
      このプライバシーポリシーにおいて、個人情報とは個人に関する情報であり、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することが出来るものをいいます。また、その情報のみでは識別できないが、他の情報と容易に照合することが出来、それにより特定の個人を識別することができるものも含まれます。
      <br />
      <br />
      個人情報の利用目的
      <br />
      <br />
      当社が取得する、または保有する個人情報は、以下の目的のために利用いたします。
      <br />
      <br />
      お取引先の皆様に関する個人情報
      <br />
      ・業務上必要なご連絡、契約の履行、商談等のため
      <br />
      ・取引先情報の管理のため
      <br />
      <br />
      <br />
      お問い合わせいただく皆様に関する個人情報
      <br />
      ・お問い合わせ対応のため
      <br />
      <br />
      <br />
      採用応募者の個人情報
      <br />
      ・採用業務（採用検討、面接、各種連絡）のため
      <br />
      <br />
      <br />
      当社従業者の個人情報
      <br />
      ・人事業務
      <br />
      ・総務業務
      <br />
      ・給与関係
      <br />
      ・各種連絡
      <br />
      ・各種業務管理
      <br />
      <br />
      <br />
      直接書面取得以外で取得する場合の個人情報
      ・受託した業務の遂行およびその契約履行のため
      なお、上記の利用目的の達成に必要な範囲内で当社の関係会社、取次店、取引先などに提供または受託することがあります。
      <br />
      <br />
      その他、個別に書面で明示したとおりの利用目的とします。
      <br />
      <br />
      個人情報の第三者への提供
      <br />
      <br />
      当社は、原則としてご本人の同意を得ずに個人情報を第三者に提供しません。第三者へ提供する場合には、提供先・提供内容を特定し、ご本人の事前の同意を得た場合に限り提供します。
      ただし、以下の場合はこの限りではありません。 法令に基づく場合
      人の生命、身体または財産の保護のために必要なある場合であって、ご本人の同意を得ることが困難であるとき
      公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難であるとき
      国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼす恐れがあるとき
      <br />
      <br />
      開示等の手続きについて
      <br />
      <br />
      当社では、保有個人情報に関して、ご本人の情報の開示等（利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去及び第三者への提供の停止）を希望される場合には、お申し出いただいた方がご本人あるいはその代理人であることを確認した上で、合理的な期間及び範囲で回答、情報の訂正、追加または削除、利用停止、消去をします。
      ただし、これらの情報の一部または全部を利用停止または消去した場合、不本意ながらご要望に沿ったサービスの提供が出来なくなることがあります。（なお、関係法令に基づき保有しております情報については、利用停止または消去のお申し出に応じられない場合があります。）
      <br />
      <br />
      免責事項
      <br />
      <br />
      以下の場合には、当社は何ら責任を負いません。
      ご本人が当社サービスの機能または別の手段を用いて第三者に個人情報を明らかにした場合
      ご本人が当社サービス上にて開示した情報等により、個人を識別できてしまった場合
      <br />
      <br />
      お問合せ窓口
      <br />
      <br />
      【窓口の名称】
      <br />
      個人情報問合せ窓口
      <br />
      <br />
      【連絡先】 〒530-0011 大阪府大阪市北区大深町1番1号 グランフロント大阪
      北館6階
      <br />
      TEL.06-7639-1480 FAX.06-7639-1481
      <br />
      Email：info@suprieve.com
    </p>
  </>
);
